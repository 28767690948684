
import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux'
import store from './store'
import { ThemeProvider } from '@mui/material/styles';
import {getTheme} from './theme';
import { GlobalStyles } from '@mui/system';

const origin = `${window.location.origin}/app/tenant/`;

const {tenant} = await import(/* webpackIgnore: true */origin);


const root = createRoot(document.getElementById('root'));
document.title = tenant.name;
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={getTheme(tenant)} >
    <GlobalStyles styles={(theme)=>({
        a: {
          color: theme.palette.primary.main,
          textDecorationColor: theme.palette.primary.main
        },
        '.card-header': {
          background: theme.palette.primary.main,
        },
        
      })}/>
    <Provider store={store}>
      <App tenant={tenant} />
    </Provider>
  </ThemeProvider>
  // </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
